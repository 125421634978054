<template lang='pug'>
tr.loan-financials-transaction-list-item
  td {{ transaction.id }}
  td {{ $t('common:formatDate', { value: transaction.time || transaction.date }) }}
  td {{ debitCredit }}
  td(v-if = 'vat')
  td.money {{ transaction.amount | money('0,0.00[0000] $')}}
  td {{ transaction.description }}
  td {{ transaction.fromAccountType }}
  td {{ transaction.toAccountType }}
</template>


<script>
export default {
  name: 'loan-financials-transaction-list-item',

  props: {
    transaction: {
      type: Object,
      required: true
    },
    vat: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    debitCredit () {
      return this.transaction.credit ? this.$t('credit') : this.$t('debit')
    }
  }
}
</script>


<i18n>
en:
  debit: "Debit"
  credit: "Credit"
et:
  debit: "Debit"
  credit: "Credit"
ru:
  debit: "Debit"
  credit: "Credit"
</i18n>
