<template lang='pug'>
.taxation-list.card
  .card-header {{ $t('title') }}
    router-link.float-right(
      to = 'new'
      append
    ) {{ $t('newLink') }}
  .card-body
    fi-table(
      :fields      = 'fields'
      :items       = 'sortClassifiers'
      :empty-label = '$t("notFound")'
      loader       = 'taxation:list:fetch'
    )
      template(#default = '{ items }')
        taxation-list-item.animated.fadeIn(
          v-for     = 'item in items'
          :key      = 'item.id'
          :tax-rate = 'item'
        )
    fi-pagination(
      v-if    = 'taxationListPages > 1'
      :pages  = 'taxationListPages'
      v-model = 'pagination.page'
    )
</template>


<script>
import FiPagination from '@/components/FiPagination'
import FiTable from '@/components/FiTable'
import TaxationListItem from '@/views/admin/taxation/TaxationListItem'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'taxation-list',

  components: { TaxationListItem, FiPagination, FiTable },

  i18nOptions: {},

  data: () => ({
    pagination: {
      page: 0,
      size: 25
    }
  }),

  computed: {
    ...mapState('taxation', ['taxationList', 'taxationListPages']),
    ...mapGetters('classifiers', ['classifierById']),
    fields () {
      return [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'countryId',
          label: this.$t('country')
        },
        {
          key: 'taxRate',
          label: this.$t('taxRate')
        },
        {
          key: 'dayOfPayment',
          label: this.$t('dayOfPayment')
        },
        {
          key: 'authorityName',
          label: this.$t('authName')
        },
        {
          key: 'authorityAccountNumber',
          label: this.$t('authAccountNumber')
        },
        {
          key: 'bic',
          label: this.$t('bic')
        },
        {
          key: 'taxationPayableAccountBalance',
          label: this.$t('taxationPayable'),
          class: 'money'
        },
        {
          key: 'type',
          label: this.$t('taxationType')
        },
        {
          key: 'defaultTax',
          label: this.$t('default')
        },
        {}
      ]
    },
    sortClassifiers () {
      const list = JSON.parse(JSON.stringify(this.taxationList))
      list.forEach((current) => {
        current.countryName = this.classifierById('countries', current.countryId).human
      })
      const sortList = list.sort(function (a, b) {
        const nameA = a.countryName
        const nameB = b.countryName
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
      return sortList
    }
  },

  watch: {
    'pagination.page': {
      handler () {
        this.loadTaxationList({ filters: this.pagination })
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions('taxation', ['loadTaxationList'])
  }
}
</script>


<i18n>
en:
  title:             "Taxation"
  country:           "Country"
  taxRate:           "Tax rate"
  dayOfPayment:      "Day of payment"
  authName:          "Authority name"
  authAccountNumber: "Authority account number"
  bic:               "BIC"
  taxationPayable:   "Taxation payable"
  taxationType:      "Tax type"
  newLink:           "+ New tax rate"
  notFound:          "Tax rates not found"
  default:           "Default"
et:
  title:             "Taxation"
  country:           "Country"
  taxRate:           "Tax rate"
  dayOfPayment:      "Day of payment"
  authName:          "Authority name"
  authAccountNumber: "Authority account number"
  bic:               "BIC"
  taxationPayable:   "Taxation payable"
  taxationType:      "Tax type"
  newLink:           "+ New tax rate"
  notFound:          "Tax rates not found"
  default:           "Default"
ru:
  title:             "Taxation"
  country:           "Country"
  taxRate:           "Tax rate"
  dayOfPayment:      "Day of payment"
  authName:          "Authority name"
  authAccountNumber: "Authority account number"
  bic:               "BIC"
  taxationPayable:   "Taxation payable"
  taxationType:      "Tax type"
  newLink:           "+ New tax rate"
  notFound:          "Tax rates not found"
  default:           "Default"
</i18n>
