<template lang='pug'>
b-modal.deposit-financials-modal(ref     = 'modal'
                                 v-model = 'showModal'
                                 :title  = '$t("title", { accountType })'
                                 size    = 'xl'
                                 lazy
                                 @shown  = 'resetData')
  button.btn.btn-primary.mb-3.fa-pull-right(v-if           = 'provision'
                                         :disabled      = '!transactionState.length'
                                         @click.prevent = 'showProvisionRow = true') {{ $t('writeOff')}}
  |&nbsp;
  button.btn.btn-primary.mb-3.fa-pull-right(v-if           = 'correction'
                                         @click.prevent = 'showCorrectionRow = true') {{ $t('addCorrection')}}

  fi-table(:fields     = 'fields'
           :items      = 'transactionState'
           :loader     = 'loader'
           :emptyLabel = '$t("notFound")'
           responsive
           @sort       = 'setSort')
    template(slot = 'custom-row')
      tr.animated.fadeIn(v-if = 'showProvisionRow')
        td
        td {{ $t('common:formatDate', { value: systemData.nextDayChange }) }}
        td {{ $t('credit') }}
        td
          fi-form-field.mb-0(:state = '!$v.provisionData.amount.$error')
            fi-money-input.form-control.form-control-sm.mr-2(v-model        = '$v.provisionData.amount.$model'
                                                             :class         = '{ "is-invalid": $v.provisionData.amount.$error }'
                                                             :decimal-limit = 6
                                                             autofocus)
        td
          fi-form-field.mb-0(:state = '!$v.provisionData.comment.$error')
            input.form-control.form-control-sm.mr-2(v-model = '$v.provisionData.comment.$model'
                                                    :class  = '{ "is-invalid": $v.provisionData.comment.$error }')
        td {{ provisionAccount.human }}
        td {{ classifierByName('accountTypes', 'SYSTEM').human }}
          button.btn.btn-primary.btn-sm.mr-2.ml-2(@click.prevent = 'saveProvision') {{ $t('common:save') }}
            i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'provisioning')
          button.btn.btn-secondary.btn-sm(@click.prevent = 'resetData') {{ $t('common:cancel') }}
      tr.animated.fadeIn(v-if = 'showCorrectionRow')
        td
        td {{ $t('common:formatDate', { value: systemData.nextDayChange }) }}
        td
          fi-switch(v-model   = 'correctionData.debit'
                    :on-text  = '$t("debit")'
                    :off-text = '$t("credit")')
        td
          fi-form-field.mb-0(:state = '!$v.correctionData.amount.$error')
            fi-money-input.form-control.form-control-sm.mr-2(v-model        = '$v.correctionData.amount.$model'
                                                             :class         = '{ "is-invalid": $v.correctionData.amount.$error }'
                                                             :decimal-limit = 6
                                                             autofocus)
        td
          fi-form-field.mb-0(:state = '!$v.correctionData.comment.$error')
            input.form-control.form-control-sm.mr-2(v-model = '$v.correctionData.comment.$model'
                                                    :class  = '{ "is-invalid": $v.correctionData.comment.$error }')
        td(colspan = 2)
          button.btn.btn-primary.btn-sm.mr-2(@click.prevent = 'saveCorrection') {{ $t('common:save') }}
            i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'correcting')
          button.btn.btn-secondary.btn-sm(@click.prevent = 'resetData') {{ $t('common:cancel') }}

    template(v-slot = '{ items }')
      loan-financials-transaction-list-item(v-for        = 'transaction in items'
                                            :key         = 'transaction.id'
                                            :transaction = 'transaction')
  fi-pagination(v-if    = 'transactionsPages > 1'
                :pages  = 'transactionsPages'
                v-model = 'pagination.page')
  template(slot = 'modal-footer')
    button.btn.btn-secondary(@click.prevent = '$refs.modal.hide()') {{ $t("common:close") }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiSwitch from '@/components/FiSwitch'
import FiTable from '@/components/FiTable'
import LoanFinancialsTransactionListItem from '@/views/loans/LoanFinancials/LoanFinancialsTransactionListItem'
import FiPagination from '@/components/FiPagination'
import { mapActions, mapGetters, mapState } from 'vuex'
import { validation, validators } from '@/validation/index'

export default {
  name: 'deposit-financials-list-modal',

  components: {
    FiSwitch,
    FiMoneyInput,
    FiFormField,
    FiPagination,
    LoanFinancialsTransactionListItem,
    FiTable
  },

  mixins: [validation],

  props: {
    value: {
      type: Boolean,
      default: false
    },
    accountType: {
      type: [String, Array],
      default: ''
    },
    totalDebt: {
      type: [Number, String],
      default: ''
    },
    transactionState: {
      type: [Array, Function],
      required: true
    },
    transactionsPages: {
      type: [Number, Function],
      default: 0
    },
    loader: {
      type: String,
      required: true
    },
    provision: {
      type: Boolean,
      default: false
    },
    correction: {
      type: Boolean,
      default: false
    }
  },

  i18nOptions: {},

  data () {
    return {
      provisionData: {
        amount: this.totalDebt,
        accountType: '',
        comment: null
      },
      correctionData: {
        amount: null,
        accountType: '',
        comment: null,
        debit: false
      },
      showProvisionRow: false,
      showCorrectionRow: false,
      pagination: {
        size: 25,
        page: 0
      }
    }
  },

  computed: {
    ...mapState('loans', ['systemData']),
    ...mapGetters('classifiers', ['classifierByName']),
    provisioning () {
      return this.$vueLoading.isLoading(`loan:${this.$route.params.id}:provision:save`)
    },
    correcting () {
      return this.$vueLoading.isLoading(`loan:${this.$route.params.id}:correction:save`)
    },
    provisionAccount () {
      return this.classifierByName('accountTypes', [
        'LEDGER_FEE_RECEIVABLE'
      ].find(type => (Array.isArray(this.accountType) && this.accountType.includes(type)) || this.accountType)) ?? {}
    },
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    fields () {
      return [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'time',
          label: this.$t('date'),
          sort: 'time'
        },
        {
          key: 'dc',
          label: this.$t('dc')
        },
        {
          key: 'amount',
          label: this.$t('amount'),
          class: 'money'
        },
        {
          key: 'comment',
          label: this.$t('comment')
        },
        {
          key: 'accountFrom',
          label: this.$t('accountFrom')
        },
        {
          key: 'accountTo',
          label: this.$t('accountTo')
        }
      ]
    }
  },

  watch: {
    'pagination.page': 'search',
    searchParams: {
      handler () {
        this.pagination.page = 0
      },
      deep: true
    }
  },

  validations () {
    return {
      provisionData: {
        amount: {
          required: validators.requiredIf(function () {
            return this.showProvision && this.showProvisionRow
          })
        },
        comment: {
          required: validators.requiredIf(function () {
            return this.showProvision && this.showProvisionRow
          })
        }
      },
      correctionData: {
        amount: {
          required: validators.requiredIf(function () {
            return this.showCorrection && this.showCorrectionRow
          })
        },
        comment: {
          required: validators.requiredIf(function () {
            return this.showCorrection && this.showCorrectionRow
          })
        }
      }
    }
  },

  methods: {
    ...mapActions('deposits', ['saveDepositProvision', 'saveDepositCorrection']),
    resetData () {
      this.$v.$reset()
      Object.assign(this.$data, this.$options.data.apply(this))
      this.provisionData.accountType = this.provisionAccount.code
      this.correctionData.accountType = this.accountType
      this.search()
    },
    search () {
      this.$emit('search', { filters: this.pagination })
    },
    async saveProvision () {
      if (this.validate()) {
        await this.saveDepositProvision({
          contractId: this.$route.params.id,
          provisionData: this.provisionData
        })
        this.resetData()
        this.$emit('update')
      }
    },
    async saveCorrection () {
      if (this.validate()) {
        await this.saveDepositCorrection({
          contractId: this.$route.params.id,
          correctionData: this.correctionData
        })
        this.resetData()
        this.$emit('update')
      }
    },
    setSort (sort) {
      this.searchParams.sort = sort
    }
  }
}
</script>


<i18n>
en:
  title:         "{{ accountType }} transactions"
  writeOff:      "Write off"
  addCorrection: "Add correction"
  debit:         "Debit"
  credit:        "Credit"
  date:          "Date"
  dc:            "Debit/Credit"
  amount:        "Amount"
  comment:       "Comments"
  accountFrom:   "Account from"
  accountTo:     "Account to"
  notFound:      "Transactions not found"
et:
  title:         "{{ accountType }} transactions"
  writeOff:      "Write off"
  addCorrection: "Add correction"
  debit:         "Debit"
  credit:        "Credit"
  date:          "Date"
  dc:            "Debit/Credit"
  amount:        "Amount"
  comment:       "Comments"
  accountFrom:   "Account from"
  accountTo:     "Account to"
  notFound:      "Transactions not found"
ru:
  title:         "{{ accountType }} transactions"
  writeOff:      "Write off"
  addCorrection: "Add correction"
  debit:         "Debit"
  credit:        "Credit"
  date:          "Date"
  dc:            "Debit/Credit"
  amount:        "Amount"
  comment:       "Comments"
  accountFrom:   "Account from"
  accountTo:     "Account to"
  notFound:      "Transactions not found"
</i18n>
