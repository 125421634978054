<template lang='pug'>
.taxation-details.card
  .card-header {{ title }}
  .card-body
    v-loading(loader = 'taxation:rate:fetch')
      template(#spinner)
        .text-center: i.fa.fa-spinner.fa-pulse.fa-lg
      form.form.animated.fadeIn(
        @submit.prevent = 'submit'
        novalidate
      )
        fi-form-field(
          :label     = '$t("country")'
          label-cols = 2
        )
          fi-select(
            v-model  = 'taxRateData.countryId'
            :options = 'optionsFromClassifier("countries")'
            sm
          )
        fi-form-field(
          :label     = '$t("rate")'
          label-cols = 2
        )
          percent-input.form-control.form-control-sm(v-model = 'taxRateData.rate')
        fi-form-field(
          :label     = '$t("dayOfPayment")'
          label-cols = 2
        )
          input.form-control.form-control-sm(v-model.number = 'taxRateData.dayOfPayment')
        fi-form-field(
          :label     = '$t("authName")'
          label-cols = 2
        )
          input.form-control.form-control-sm(v-model.trim = 'taxRateData.authorityName')
        fi-form-field(
          :label     = '$t("authAccountNumber")'
          label-cols = 2
        )
          input.form-control.form-control-sm(v-model.trim = 'taxRateData.authorityAccountNumber')
        fi-form-field(
          :label     = '$t("bic")'
          label-cols = 2
        )
          input.form-control.form-control-sm(v-model.trim = 'taxRateData.bic')
        fi-form-field(
          :label     = '$t("type")'
          label-cols = 2
        )
          fi-select(
            v-model  = 'taxRateData.type'
            :options = 'optionsFromClassifier("taxationType")'
            sm
          )
        fi-form-field(
          :label     = '$t("default")'
          label-cols = 2
        )
          fi-switch(
            v-model   = 'taxRateData.default'
          )
        button.btn.btn-primary(
          type      = 'submit'
          :disabled = 'saving'
        ) {{ $t("common:save") }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        | &nbsp;
        button.btn.btn-secondary(
          :disabled      = 'saving'
          @click.prevent = 'cancel'
        ) {{ $t("common:cancel") }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiSelect from '@/components/FiSelect'
import PercentInput from '@/components/PercentInput'
import FiSwitch from '@/components/FiSwitch'
import { mapGetters, mapActions, mapState } from 'vuex'
import pick from 'lodash/pick'
export default {
  name: 'taxation-details',

  components: { PercentInput, FiMoneyInput, FiSelect, FiFormField, FiSwitch },

  props: {
    id: {
      type: [Number, String],
      default: ''
    }
  },

  i18nOptions: {},

  data: () => ({
    taxRateData: {
      authorityAccountNumber: '',
      authorityName: '',
      bic: '',
      countryId: '',
      rate: '',
      dayOfPayment: '',
      default: false
    }
  }),

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    ...mapState('taxation', ['taxRate', 'taxationList', 'taxationListPages']),
    title () {
      return this.id ? this.$t('editTax', { id: this.id }) : this.$t('menu:newTaxation')
    },
    saving () {
      return this.$vueLoading.isLoading('taxation:rate:save')
    },
    disabled () {
      let disabled = false
      if (this.taxRateData.countryId) {
        const options = []
        this.taxationList.forEach((current) => {
          if (current.countryId === this.taxRateData.countryId) {
            options.push(current)
          }
        })
        if (this.id) {
          options.forEach((current) => {
            if (current.id !== this.id && current.default === true) {
              disabled = true
              this.taxRateData.default = false
            }
          })
        } else {
          options.forEach((current) => {
            if (current.default === true) {
              disabled = true
              this.taxRateData.default = false
            }
          })
        }
      }
      return disabled
    }
  },

  async created () {
    if (this.id) {
      await this.loadTaxRate({ taxRateId: this.id })
    }

    this.resetData()
  },

  methods: {
    ...mapActions('taxation', ['loadTaxRate', 'createTaxRate', 'updateTaxRate']),
    resetData () {
      Object.assign(this.$data, this.$options.data.apply(this))
      if (this.id) {
        this.taxRateData = {
          ...this.taxRateData,
          ...pick(this.taxRate, Object.keys(this.taxRateData)),
          type: this.taxRate.type.code
        }
      }
    },
    async submit () {
      if (this.id) {
        await this.updateTaxRate({ taxRateData: this.taxRateData, taxRateId: this.id })
      } else {
        await this.createTaxRate({ taxRateData: this.taxRateData })
      }
      this.$router.push({ name: 'TaxationList' })
    },
    cancel () {
      if (this.saving) return
      this.$router.push({ name: 'TaxationList' })
    }
  }
}
</script>


<i18n>
en:
  editTax:           "Edit tax #{{ id }}"
  country:           "Country"
  rate:              "Tax rate"
  authName:          "Authority name"
  authAccountNumber: "Authority account number"
  bic:               "BIC"
  dayOfPayment:      "Day of payment"
  type:              "Tax type"
  default:           "Default"
et:
  editTax:           "Edit tax #{{ id }}"
  country:           "Country"
  rate:              "Tax rate"
  authName:          "Authority name"
  authAccountNumber: "Authority account number"
  bic:               "BIC"
  dayOfPayment:      "Day of payment"
  type:              "Tax type"
  default:           "Default"
ru:
  editTax:           "Edit tax #{{ id }}"
  country:           "Country"
  rate:              "Tax rate"
  authName:          "Authority name"
  authAccountNumber: "Authority account number"
  bic:               "BIC"
  dayOfPayment:      "Day of payment"
  type:              "Tax type"
  default:           "Default"
</i18n>
