<template lang='pug'>
tr.taxation-list-item
  td {{ taxRate.id }}
  td {{ classifierById('countries', taxRate.countryId).human }}
  td {{ taxRate.rate | percent }}
  td {{ taxRate.dayOfPayment }}
  td {{ taxRate.authorityName }}
  td {{ taxRate.authorityAccountNumber }}
  td {{ taxRate.bic }}
  td.money
    button.btn.btn-link.btn-sm(@click = 'showTransactions = true') {{ taxRate.taxationPayableAccountBalance || 0 | money }}
  td {{ classifierById(taxRate.type.classifier, taxRate.type.code).human }}
  td
    fi-switch(
        v-model   = 'taxRate.default'
        read-only
      )
  td.text-center
    .btn-group
      router-link.btn.btn-link.btn-sm(:to = '{ name: "TaxationEdit", params: { id: taxRate.id } }')
        i.far.fa-edit.fa-lg.fa-fw
      button.btn.btn-link.text-danger.btn-sm(v-fi-confirm = '{ ok: deleteTaxRate, message: $t("deleteConfirm")}')
        i.fa.fa-spinner.fa-pulse.fa-lg.fa-fw(v-if = 'deleting')
        i.far.fa-trash-alt.fa-lg.fa-fw(v-else)
  deposit-financials-list-modal(
    v-model             = 'showTransactions'
    :account-type       = 'accountType'
    :transaction-state  = 'systemTransactions'
    :transactions-pages = 'systemTransactionsPages'
    loader              = 'taxation:systemTransactions:fetch'
    @search             = 'loadTaxationTransactions'
  )
</template>


<script>
import DepositFinancialsListModal from '@/views/deposits/DepositFinancialsListModal'
import FiSwitch from '@/components/FiSwitch'
import { mapActions, mapGetters, mapState } from 'vuex'

const TYPE_ACCOUNT_MAP = {
  WITHHOLD_TAX: 'WH_TAXATION_PAYABLE',
  VAT: 'VAT_TAXATION_PAYABLE'
}

export default {
  name: 'taxation-list-item',

  components: { DepositFinancialsListModal, FiSwitch },

  props: {
    taxRate: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    showTransactions: false,
    searchParams: {
      sort: 'id,desc'
    }
  }),

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    ...mapState('taxation', ['systemTransactions', 'systemTransactionsPages']),
    deleting () {
      return this.$vueLoading.isLoading(`taxation:rate:${this.taxRate.id}:delete`)
    },
    accountType () {
      return TYPE_ACCOUNT_MAP[this.taxRate.type.code]
    }
  },

  methods: {
    ...mapActions('taxation', ['removeTaxRate', 'loadTaxationSystemTransactions']),
    async deleteTaxRate ({ done }) {
      await this.removeTaxRate({ taxRateId: this.taxRate.id })

      done()
    },
    loadTaxationTransactions ({ filters }) {
      this.loadTaxationSystemTransactions({ filters: { accountType: this.accountType, ...filters, ...this.searchParams } })
    }
  }
}
</script>


<i18n>
en:
  deleteConfirm: "Are you sure to delete this tax rate?"
et:
  deleteConfirm: "Are you sure to delete this tax rate?"
ru:
  deleteConfirm: "Are you sure to delete this tax rate?"
</i18n>
